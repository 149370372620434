<template>
  <div class="prescription-panel" v-if="prescription">
    <sheet-container
        ref="sheetContainer"
        :sheet-list="sheetList"
        :sheet-record-list="sheetRecordList"
        :show-contents="false"
        mode="view"
        :show-abstract.sync="isShowAbstract"
    >
      <div slot="footer" class="padding-horizontal footer layout-vertical">
        <div class="layout-horizontal layout-middle margin-bottom" style="height: 48px;">
          <div class="layout-inflexible font-size-medium padding-left">
            <span @click="handleReadCommentClick" class="btn-read-comment">
              <span v-if="prescription.commentCount > 0">查看点评</span>
              <span v-else><i class="far fa-comment"></i> 点评</span>
              <span v-if="prescription.commentCount > 0">({{ prescription.commentCount }})</span>
               <i class="fa fa-angle-right margin-left-small"></i></span>
          </div>
          <div class="layout-flexible"></div>
          <div class="layout-inflexible padding-right">
            <span
                class="btn-comment"
                :class="{
                'font-color-danger': prescription.commentInfo && prescription.commentInfo.thumbUpAdded,
                }"
                @click="handleThumbUpClick"
            >
              <i class="far fa-thumbs-up"></i>
              <span
                  v-if="prescription.commentInfo && prescription.commentInfo.thumbUpCount > 0"
                  class="btn-comment"
              >
                {{ prescription.commentInfo.thumbUpCount }}
              </span>
            </span>
          </div>
        </div>
        <div v-for="comment in prescription.commentList" :key="comment.id">
          <comment :comment="comment" @delete="handleCommentDeleteClick"></comment>
        </div>
        <div class="font-align-center margin-top" v-if="prescription.commentList.length < prescription.commentCount">
          <ys-button type="secondary" size="small" @click="handleMoreCommentClick">加载更多点评</ys-button>
        </div>
        <ys-textarea ref="commentInput" v-resize="handleCommentInputResize" placeholder="输入点评" class="margin-top"
                     v-model="commentMessageInputted" @keyup.enter.native="addComment"></ys-textarea>
        <ys-button class="layout-self-right margin-top margin-bottom-large" size="small" type="secondary"
                   @click="addComment">发送
        </ys-button>
      </div>
    </sheet-container>
  </div>
</template>

<script>
import SheetContainer from "@/components/sheet/SheetContainer";
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";
import YsTextarea from "@/components/wedigets/YsTextarea";
import Comment from "@/components/comment/Comment";
import TextUtils from "@/assets/javascript/text-utils";

function doLoadPrescription(id) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqGet({
    path: '/prescription/get/with_sheet_records',
    data: {
      id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.prescription = res.data;
    this.sheetList = res.data.sheetRecordList.map(x => x.sheet);
    for (let sheetRecord of res.data.sheetRecordList) {
      delete sheetRecord.sheet;
    }
    this.sheetRecordList = res.data.sheetRecordList;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentInfo() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/v1/prescription/comment/info/get',
    data: {
      prescriptionId: this.prescriptionId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.prescription.commentCount = res.data.commentCount;
    this.prescription.commentInfo = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentListAfter() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/v1/prescription/comment/list/get/after',
    data: {
      prescriptionId: this.prescriptionId,
      id: this.prescription.commentList.length > 0 ? this.prescription.commentList[this.prescription.commentList.length - 1].id : null,
      count: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.prescription.commentList, res.data);
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddComment(message) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/v1/prescription/comment/add',
    data: {
      prescriptionId: this.prescriptionId,
      organizationId: this.organizationId,
      message,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.prescription.commentInfo) {
      this.prescription.commentInfo.commentCount++;
    }
    this.commentMessageInputted = null;
    this.busyLoadingCode = 0;
    doLoadCommentListAfter.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveComment(comment) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/comment/remove',
    data: {
      id: comment.id,
      commentId: comment.commentId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    for (let n = this.prescription.commentList.length - 1; n >= 0; n--) {
      if (this.prescription.commentList[n].id === comment.id) {
        this.prescription.commentList.splice(n, 1);
        break;
      }
    }
    if (this.prescription.commentInfo) {
      this.prescription.commentInfo.commentCount--;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/v1/prescription/thumbup/add',
    data: {
      prescriptionId: this.prescriptionId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.prescription.commentInfo) {
      this.prescription.commentInfo.thumbUpCount++;
      this.prescription.commentInfo.thumbUpAdded = true;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = 0;
  this.$reqPost({
    path: '/v1/prescription/thumbup/remove',
    data: {
      prescriptionId: this.prescriptionId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.prescription.commentInfo) {
      this.prescription.commentInfo.thumbUpCount--;
      this.prescription.commentInfo.thumbUpAdded = false;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PrescriptionPanel",
  mixins: [httpapi],
  components: {Comment, YsTextarea, YsButton, SheetContainer},
  props: {
    prescriptionId: Number,
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      prescription: null,
      sheetList: [],
      sheetRecordList: [],

      isShowAbstract: true,

      commentMessageInputted: null,
    }
  },
  watch: {
    prescriptionId: {
      handler() {
        this.loadingCode++;
        this.prescription = 0;
        this.sheetList = [];
        this.sheetRecordList = [];
        this.isShowAbstract = true;
        this.commentMessageInputted = null;
        if (this.prescriptionId) {
          this.loadPrescription();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadPrescription() {
      doLoadPrescription.bind(this)(this.prescriptionId);
    },
    handleReadCommentClick() {
      this.$refs.sheetContainer.scrollToBottom();
    },
    handleThumbUpClick() {
      if (this.prescription.commentInfo?.thumbUpAdded) {
        doRemoveThumbUp.bind(this)();
      } else {
        doAddThumbUp.bind(this)();
      }
    },
    addComment() {
      if (TextUtils.isBlank(this.commentMessageInputted)) {
        return;
      }
      doAddComment.bind(this)(this.commentMessageInputted);
    },
    handleCommentDeleteClick(comment) {
      doRemoveComment.bind(this)(comment);
    },
    handleMoreCommentClick() {
      doLoadCommentListAfter.bind(this)();
    },
    handleCommentInputResize() {
      if(this.$refs.commentInput.focused) {
        this.$refs.sheetContainer.scrollToBottom();
      }
    }
  }
}
</script>

<style scoped>

.footer {
  min-height: 48px;
  background-color: white;
}

.btn-comment {
  cursor: pointer;
}

.btn-read-comment {
  cursor: pointer;
}

</style>