<template>
  <div class="prescription-list-panel layout-horizontal">
    <div class="layout-flexible layout-vertical">
      <div class="title layout-horizontal layout-middle padding layout-inflexible">
        <back-button class="font-size-small" @click="$router.back()"></back-button>
        <span class="font-weight-bold">饮食处方管理</span>
      </div>
      <div class="layout-flexible">
        <ys-infinite-scroll-view style="height: 100%;">
          <div class="layout-vertical padding">
            <div
                class="btn-send-prescription margin-bottom-small"
                @click="handleSendPrescriptionClick"
            >
            <span>
              <i class="fas fa-plus"></i> 发送饮食处方
            </span>
            </div>
            <div
                class="list-item prescription layout-horizontal layout-middle"
                :class="{
                   'is-active': prescription && item.id === prescription.id,
                   'is-stopped': item.state !== BusinessState.STARTED,
                }"
                v-for="(item, index) in prescriptionList"
                :key="item.id"
                @click="handlePrescriptionClick(index)">
              <div class="prescription-icon">
                <i class="fas fa-prescription-bottle"></i>
              </div>
              <div
                  class="margin-left-large layout-flexible"
              >
                <div class="font-size-medium title">{{ item.title }}</div>
                <div class="font-size-small font-color-placeholder" style="margin-top: 2px;">
                  <template v-if="item.state === BusinessState.STARTED">
                    <span class="mark-started"><i class="fa fa-play"></i> 执行中</span>
                    <span class="margin-left-small">开始于：{{ TimeUtils.formatNormal(item.startTime) }}</span>
                  </template>
                  <template v-else>
                    <span class="mark-stopped font-color-danger">已停止</span>
                  </template>
                </div>
              </div>
              <div class="operations layout-inflexible layout-horizontal layout-middle">
                <el-tooltip content="停止饮食处方" :open-delay="500" v-if="item.state === BusinessState.STARTED">
                  <ys-button size="small" type="secondary" round lighting
                             @click.stop="handleStopPrescriptionClick(index)">
                    <i class="fa fa-stop"></i>
                  </ys-button>
                </el-tooltip>
                <el-tooltip content="重新发送" :open-delay="500" v-else>
                  <ys-button size="small" type="secondary" round lighting
                             @click.stop="handleRestartPrescriptionClick(index)">
                    <i class="fa fa-play font-color-placeholder"></i>
                  </ys-button>
                </el-tooltip>
                <el-tooltip content="删除饮食处方" :open-delay="500" class="margin-left">
                  <ys-button size="small" type="secondary" round lighting
                             @click.stop="handlePrescriptionDeleteClick(index)">
                    <i class="far fa-trash-alt font-color-danger"></i>
                  </ys-button>
                </el-tooltip>

              </div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <div class="layout-inflexible" style="width: 418px; background-color: #c2e7b0">
      <prescription-panel
          style="height: 100%"
          :prescription-id="prescription ? prescription.id : null"
          :organization-id="organizationId"
      >
      </prescription-panel>
    </div>
    <prescription-template-picker-dialog :visible.sync="prescriptionTemplatePickerDialogVisible"
                                         :organization-id="organizationId"
                                         @pick="handlePrescriptionTemplatePicked"></prescription-template-picker-dialog>
    <prescription-input-dialog ref="prescriptionInputDialog"
                               :template-id="prescriptionTemplatePickedId"
                               :prescription-id="prescriptionPickedId"
                               :user-id="userId"
                               :organization-id="organizationId"
                               :visible.sync="prescriptionInputDialogVisible"
                               @confirm="handlePrescriptionInputConfirm"></prescription-input-dialog>
    <delete-dialog :visible.sync="deleteRemindDialogVisible" message="您确定要删除该饮食处方吗？"
                   @confirm="handlePrescriptionDeleteConfirm"></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import PrescriptionPanel from "@/pages/prescription/panel/PrescriptionPanel";
import httpapi from "@/assets/javascript/httpapi";

import BusinessState from "@/assets/javascript/business-state";
import {TimeUtils} from "@/assets/javascript/time-utils";
import PrescriptionTemplatePickerDialog from "@/components/dialog/PrescriptionTemplatePickerDialog";
import PrescriptionInputDialog from "@/pages/prescription/dialog/PrescriptionInputDialog";
import YsButton from "@/components/wedigets/YsButton";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import BackButton from "@/components/backbutton/BackButton";


function doLoadPrescriptionList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/prescription/list/get/by_organization',
    data: {
      userId: this.userId,
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.prescriptionList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;

    if(this.pageNum == 1 && !this.prescription && this.$route.query.id) {
      this.busyLoadingCode = 0;
      doLoadPrescriptionInfo.bind(this)(this.$route.query.id);
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPrescriptionInfo(id) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/prescription/get',
    data: {
      id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.prescriptionList, [res.data]);
    this.prescription = res.data;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doStopPrescription(id) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/prescription/stop',
    data: {
      id,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    doLoadPrescriptionInfo.bind(this)(id);
    window.eventBus.$emit('update:prescription');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doDeletePrescription(id) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/prescription/remove',
    data: {
      id,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    let deleteIndex = -1;
    for (let n = this.prescriptionList.length - 1; n >= 0; n--) {
      if (this.prescriptionList[n].id === id) {
        deleteIndex = n;
        this.prescriptionList.splice(n, 1);
        break;
      }
    }
    if (deleteIndex >= this.prescriptionList.length) {
      deleteIndex--;
    }
    if (deleteIndex < this.prescriptionList.length) {
      this.prescription = this.prescriptionList[deleteIndex];
    }
    window.eventBus.$emit('update:prescription');
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

export default {
  name: "PrescriptionListPanel",
  mixins: [httpapi],
  components: {
    BackButton,
    DeleteDialog,
    YsButton,
    PrescriptionInputDialog, PrescriptionTemplatePickerDialog, PrescriptionPanel, YsInfiniteScrollView
  },
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      BusinessState,
      TimeUtils,

      loadingCode: 1,
      busyLoadingCode: 0,

      prescriptionList: [],
      pageNum: 1,
      totalPages: 0,

      prescription: null,

      prescriptionTemplatePickerDialogVisible: false,

      prescriptionTemplatePickedId: null,
      prescriptionPickedId: null,
      prescriptionInputDialogVisible: false,

      deleteRemindDialogVisible: false,

    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.reload();
      },
      immediate: true,
    }
  },
  methods: {
    loadPrescriptionList(pageNum = 1) {
      doLoadPrescriptionList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadPrescriptionList(this.pageNum + 1);
      }
    },
    loadPrescriptionById(id) {
      doLoadPrescriptionInfo.bind(this)(id);
    },
    handlePrescriptionClick(index) {
      this.prescription = this.prescriptionList[index];
    },
    handleSendPrescriptionClick() {
      this.prescriptionTemplatePickerDialogVisible = true;
    },
    handlePrescriptionTemplatePicked(prescriptionTemplateList) {
      this.prescriptionPickedId = null;
      this.prescriptionTemplatePickedId = prescriptionTemplateList[0].id;
      this.prescriptionInputDialogVisible = true;
    },
    handlePrescriptionInputConfirm(id) {
      window.eventBus.$emit('update:prescription');
      this.loadPrescriptionById(id);
    },
    handleStopPrescriptionClick(index) {
      doStopPrescription.bind(this)(this.prescriptionList[index].id);
    },
    handleRestartPrescriptionClick(index) {
      this.prescriptionTemplatePickedId = null;
      this.prescriptionPickedId = this.prescriptionList[index].id;
      this.prescriptionInputDialogVisible = true;
    },
    handlePrescriptionDeleteClick(index) {
      this.prescriptionPickedId = this.prescriptionList[index].id;
      this.deleteRemindDialogVisible = true;
    },
    handlePrescriptionDeleteConfirm() {
      if (this.prescriptionPickedId) {
        doDeletePrescription.bind(this)(this.prescriptionPickedId);
      }
    },
    reload() {
      this.loadingCode++;
      this.prescriptionList = [];
      this.pageNum = 1;
      this.totalPages = 0;
      this.prescription = null;
      if (this.userId && this.organizationId) {
        this.loadPrescriptionList();
      }
    }
  },
  activated() {
    this.reload();
  }
}
</script>

<style scoped>

.prescription.is-stopped .title, .prescription.is-stopped .prescription-icon {
  color: #bec0c0;
}

.prescription.is-active .title {
  font-weight: bold;
  color: #1f1f1f;
}

.btn-send-prescription {
  height: 100px;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #dadada;
  cursor: pointer;
  background-color: white;
}

.btn-send-prescription:hover {
  box-shadow: 0 0 4px #bec0c0;
}

.list-item {
  width: auto;
}

.mark-started {
  background-color: #f091a6;
  color: white;
  padding: 2px 4px;
  font-size: 8px;
  line-height: 12px;
  border-radius: 4px;
  box-shadow: 0 0 4px #f091a6;
}

.prescription .operations {
  visibility: hidden;
}

.prescription:hover .operations {
  visibility: visible;
}
</style>