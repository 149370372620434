<template>
  <ys-dialog :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)">
    <div slot="title" class="layout-horizontal padding-horizontal-large padding-vertical">
      <div class="layout-inflexible font-nowrap">{{title}}</div>
      <div class="layout-flexible"></div>
      <div class="btn-add-template">
        <span>
          <i class="fas fa-plus"></i> 添加处方模板
        </span>
      </div>
    </div>
    <ys-infinite-scroll-view @loadmore="loadMore" style="height: 50vh;">
      <div class="padding">
        <template v-if="selectedPrescriptionTemplateList.length > 0">
          <div class="font-size-small">已选择的模板：</div>
          <div class="layout-horizontal layout-wrap">
            <div class="template-selected margin margin-small" v-for="selectedTemplate in selectedPrescriptionTemplateList" :key="selectedTemplate.id"
              @click="handlePrescriptionTemplateClick(selectedTemplate)">
              <span>{{selectedTemplate.title}}</span>
              <span class="btn-delete-selected margin-left-small">
                <i class="el-icon-close font-size-small"></i>
              </span>
            </div>
          </div>
        </template>
        <div class="margin-top">
          <div class="list-item layout-horizontal layout-middle"
               v-for="template in prescriptionTemplateList"
               :key="template.id"
               @click="handlePrescriptionTemplateClick(template)"
          >
            <ys-checker
                class="layout-inflexible"
                :value="template.__selected"
                :disable="template.__disable"
            ></ys-checker>
            <div class="layout-inflexible margin-left-large">
              <i class="fas fa-file-medical"></i>
            </div>
            <div class="margin-left">
              <div class="font-size-medium">{{ template.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </ys-infinite-scroll-view>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" lighting @click="handleConfirmClick">
        <span>确认</span>
        <span
            v-if="selectedPrescriptionTemplateList.length > 0">({{ selectedPrescriptionTemplateList.length }}/{{ maxSelectedCount }})</span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsChecker from "@/components/wedigets/YsChecker";
import YsButton from "@/components/wedigets/YsButton";

function doLoadPrescriptionTemplateList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/prescription/template/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.prescriptionTemplateList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.refreshSelectionState();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PrescriptionTemplatePickerDialog",
  mixins: [httpapi],
  components: {YsButton, YsChecker, YsInfiniteScrollView, YsDialog},
  props: {
    visible: Boolean,
    organizationId: Number,
    title: {
      type: String,
      default: '选择饮食处方模板'
    },
    maxSelectedCount: {
      type: Number,
      default: 1,
    },
    defaultSelectedIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      isVisible: false,

      prescriptionTemplateList: [],
      pageNum: 1,
      totalPages: 0,

      selectedPrescriptionTemplateList: [],
    }
  },
  watch: {
    visible: {
      handler() {
        if(this.visible) {
          this.selectedPrescriptionTemplateList = [];
          this.refreshSelectionState();
        }
        this.isVisible = this.visible;
      },
      immediate: true,
    },
    organizationId: {
      handler() {
        if (this.organizationId) {
          this.loadPrescriptionTemplateList();
        }
      },
      immediate: true,
    },
    defaultSelectedIds: {
      handler() {
        this.refreshSelectionState();
      },
      immediate: true,
    }
  },
  methods: {
    loadPrescriptionTemplateList(pageNum = 1) {
      doLoadPrescriptionTemplateList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadPrescriptionTemplateList(this.pageNum + 1);
      }
    },
    handlePrescriptionTemplateClick(prescriptionTemplate) {
      let selectedIndex = this.getSelectedIndex(prescriptionTemplate);
      if(selectedIndex >= 0) {
        this.selectedPrescriptionTemplateList.splice(selectedIndex, 1);
      } else {
        if(this.selectedPrescriptionTemplateList.length < this.maxSelectedCount) {
          this.selectedPrescriptionTemplateList.push(prescriptionTemplate);
        }
        if(this.maxSelectedCount === 1 && this.selectedPrescriptionTemplateList.length === 1) {
          this.handleConfirmClick();
        }
      }
      this.refreshSelectionState();
    },
    handleConfirmClick() {
      if(this.selectedPrescriptionTemplateList.length === 0) {
        this.$message.warning('至少选择一项');
        return;
      }
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', this.selectedPrescriptionTemplateList);
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    getSelectedIndex(prescriptioinTemplate) {
      for(let n = 0; n < this.selectedPrescriptionTemplateList.length; n++) {
        if(this.selectedPrescriptionTemplateList[n].id === prescriptioinTemplate.id) {
          return n;
        }
      }
      return -1;
    },
    refreshSelectionState() {
      for(let template of this.prescriptionTemplateList) {
        let selected = false;
        let disabled = false;
        for(let defaultSelectedId of this.defaultSelectedIds) {
          if(defaultSelectedId === template.id) {
            selected = true;
            disabled = true;
            break;
          }
        }
        if(!disabled) {
          for(let selectedTemplate of this.selectedPrescriptionTemplateList) {
            if(selectedTemplate.id === template.id) {
              selected = true;
              break;
            }
          }
        }
        template.__selected = selected;
        template.__disable = disabled;
      }
    }
  }
}
</script>

<style scoped>

.list-item {
  width: auto;
  max-width: none;
}

.btn-add-template {
  cursor: pointer;
  user-select: none;
}

.template-selected {
  padding: 8px 16px;
  background-color: #f091a6;
  color: white;
  border-radius: 100px;
}

.template-selected .btn-delete-selected {
  visibility: hidden;
}

.template-selected:hover .btn-delete-selected {
  visibility: visible;
}



</style>